import request from '../utils/request'
import baseUrl from './baseUrl'

export const hotelJournalList = (data) => request({
  url: baseUrl + '/hotelLog/selectForBack',
  method: 'POST',
  data
})
export const hotelLog = (data) => request({
  url: baseUrl + '/hotelLog/selectForId',
  method: 'POST',
  data
})