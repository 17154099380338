<template>
  <div class="hotelLogDetails">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>日志详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" label-width="80px">
      <el-form-item label="酒店名称">
        <span>{{ hotelLogs.hotelName }}</span>
      </el-form-item>
      <el-form-item label="修改人">
        <span>{{ hotelLogs.masterName }}</span>
      </el-form-item>
      <el-form-item label="修改内容">
        <span>{{ hotelLogs.strType }}</span>
      </el-form-item>
      <el-form-item label="修改时间">
        <span>{{ hotelLogs.hotelAddTime }}</span>
      </el-form-item>
      <el-form-item label="修改前内容">
        <span>{{ hotelLogs.curContent }}</span>
      </el-form-item>
      <el-form-item label="修改后内容">
        <span>{{ hotelLogs.endContent }}</span>
      </el-form-item>
      <el-form-item label="修改类型">
        <span>{{ hotelLogs.hotelLogType }}</span>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="$router.go(-1)">返回</el-button>
  </div>
</template>
<script>
import { hotelLog } from "../../api/hotelJournal";
export default {
  name: "hotelLogDetails",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      hotelLogs: {},
    };
  },
  created() {
    this.gethotelLogDetails();
  },
  methods: {
    async gethotelLogDetails() {
      const { data } = await hotelLog({
        hotelLogId: Number(this.id),
      });
      console.log(data.data[0]);
      this.hotelLogs = data.data[0];
    },
  },
};
</script>
<style lang="less" scoped>
.hotelLogDetails {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .el-form {
    margin-top: 50px;
  }
}
</style>